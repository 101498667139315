<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="abnormalOrder">
          <el-select
              v-model="query.abnormalOrder"
              clearable
              placeholder="请选择-订单类型"
              style="width: 260px"
          >
            <el-option label="订单异常" value="1"></el-option>
            <el-option label="计费异常" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="abnormalType">
          <el-select
              v-model="query.abnormalType"
              clearable
              placeholder="请选择-订单类型"
              style="width: 260px"
          >
            <el-option label="无驶入放行" value="1"></el-option>
            <el-option label="模糊匹配" value="2"></el-option>
            <el-option label="异常放行" value="3"></el-option>
            <el-option label="重复入场无效" value="4"></el-option>
            <el-option label="强制抬杆" value="5"></el-option>
            <el-option label="自动匹配" value="6"></el-option>
            <el-option label="人工录入" value="7"></el-option>
            <el-option label="持续抬杆" value="8"></el-option>
            <el-option label="手动落杆" value="9"></el-option>
            <el-option label="手动入场" value="10"></el-option>
            <el-option label="手动离场" value="11"></el-option>
            <el-option label="外设抬杆" value="12"></el-option>
            <el-option label="相机抬杆" value="13"></el-option>
            <el-option label="抬杆重试" value="13"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="VehicleId">
          <NumplateSuggest v-model="query.VehicleId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="parkingLotInfoId">
          <SuggestParking
              v-model="query.parkingLotInfoId"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="parkingOrderType">
          <el-select
              v-model="query.parkingOrderType"
              clearable
              placeholder="请选择-订单类型"
              style="width: 260px"
          >
            <el-option label="临停" value="0"></el-option>
            <el-option label="长租" value="1"></el-option>
            <el-option label="白名单" value="2"></el-option>
            <el-option label="黑名单" value="3"></el-option>
            <el-option label="预存" value="4"></el-option>
            <el-option label="访客" value="5"></el-option>
            <el-option label="共享" value="6"></el-option>
            <el-option label="预约" value="7"></el-option>
            <el-option label="分组" value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="phoneNumber">
          <el-input
              v-model="query.phoneNumber"
              clearable
              placeholder="请输入用户手机号码"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="operatorType">
          <el-select
              v-model="query.operatorType"
              clearable
              placeholder="请选择-操作人类型"
              style="width: 260px"
          >
            <el-option label="系统" value="1"></el-option>
            <el-option label="岗亭人员" value="2"></el-option>
            <el-option label="平台人员" value="3"></el-option>
            <el-option label="运营人员" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="operatorName">
          <el-input
              v-model="query.operatorName"
              clearable
              placeholder="请输入操作人姓名"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="parkingTypeTime">
          <div class="xxd-input__wrap">
            <el-select
                v-model="query.parkingTypeTime"
                clearable
                placeholder="时间范围"
            >
              <el-option label="操作时间" value="1"></el-option>
              <el-option label="驶入时间" value="2"></el-option>
              <el-option label="驶出时间" value="3"></el-option>
            </el-select>
            <el-date-picker
                v-model="query.timeValues"
                align="right"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column align="center" label="车牌号" width="200">
            <template slot-scope="scope">
              <Numplate :type="scope.row.plateColor">{{
                  scope.row.plateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="异常类型"
              prop="spaceCode"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="查看图片"
              prop="spaceCode"
              width="200"
          >
            <template slot-scope="scope">
              <el-button
                  class="common-text"
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="checkPhoto(scope.row.id)"
              >查看
              </el-button
              >
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="订单类型"
              prop="name"
              width="200"
          >
            <template slot-scope="scope">
              <span>{{ orderType[scope.row.orderType] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="订单金额"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="用户手机号"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="驶入/驶出时间"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作车道"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作时间"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作原因"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作人类型"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作人"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 驶入驶出图片弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="dialogFormVisible"
        class="common-dialog"
        title="车辆快照"
    >
      <div class="photoShow">
        <div class="driveInPhoto">
          <div>
            <el-carousel class="photo-width" height="300px" trigger="click">
              <el-carousel-item>
                <el-image
                    :src="src"
                    @click="photoDialogFormVisible = true"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div>驶入：</div>
        </div>
        <div class="driveOutPhoto">
          <div>
            <el-carousel class="photo-width" height="300px" trigger="click">
              <el-carousel-item>
                <el-image
                    :src="src"
                    @click="photoDialogFormVisible = true"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div>驶出：</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import NumplateSuggest from "@/components/NumplateSuggest";
import SuggestParking from "@/components/SuggestParking";
import Numplate from "@/components/Numplate";

export default {
  name: "abnormal_data",
  mixins: [BaseMixin],
  components: {
    SuggestParking,
    NumplateSuggest,
    Numplate,
  },
  data() {
    return {
      isloading: false,
      query: {
        abnormalOrder: "1",
        abnormalType: "",
        VehicleId: "",
        parkingLotInfoId: "",
        parkingOrderType: "",
        phoneNumber: "",
        operatorType: "",
        operatorName: "",
        parkingTypeTime: "",
        timeValues: [],
      },
    };
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.query.timeValues = [];
      this.pagination.pageSize = 10;
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
    },
  }
};
</script>

<style lang="less" scoped>
</style>
